@import 'npm:flag-icons/css/flag-icons.css';
@import 'css/custom';

.page-header {
	position: relative;
}

.back-to-title {
	position: absolute;
	top: 0.1em;
	left: -1em;
	vertical-align: baseline;
	color: #ccc;
}

.smart-checkboxes input[type="checkbox"] {
	user-select: none;
}
