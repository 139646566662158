@import 'bootstrap/scss/functions';

$primary: #5d2bd4;
$enable-validation-icons: false;

@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/alert';
@import 'bootstrap/scss/popover';

@import 'bootstrap/scss/helpers';
@import 'bootstrap/scss/utilities/api';

@include form-validation-state('warning', $warning, '');

[data-is-visually-disabled] {
	text-decoration: line-through red;
}
